import Vue from 'vue'
import getters from './getters'
import mutations from './mutations'
import * as types from './mutation-types'
import EventBus from '../../../../services/eventBus'
import axios, { routes } from '../../../../services/http'
import apiUser from '@/api/user'

export const EVENT_USER_PROFILE_LOADED = 'user-after-loggedin'

const actions = {
  startSession (context) {
    context.commit(types.USER_START_SESSION)
    const cache = Vue.prototype.$db.usersCollection
    cache.getItem('current-token', (err, res) => {
      if (err) {
        console.error(err)
        return
      }

      if (res) {
        context.commit(types.USER_TOKEN_CHANGED, { newToken: res })
        context.dispatch('sessionAfterAuthorized')

        // if (routes.usePriceTiers) {
        //   Vue.prototype.$db.usersCollection.getItem('current-user', (err, userData) => {
        //     if (err) {
        //       console.error(err)
        //       return
        //     }
        //
        //     if (userData) {
        //       context.dispatch('setUserGroup', userData)
        //     }
        //   })
        // }
      } else {
        EventBus.$emit('session-after-nonauthorized')
      }
      EventBus.$emit('session-after-started')
    })
  },
  /**
   * Send password reset link for specific e-mail
   */
  resetPassword (context, { email }) {
    return axios().post(routes.users.resetPassword_endpoint, { email: email }, {
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response
    })
  },
  /**
   * Login user and return user profile and current token
   */
  login (context, { username, password }) {
    return axios().post(routes.users.login_endpoint, { email: username, password: password }, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then((resp) => {
        if (resp.code === 200) {
          context.state.userTokenInvalidateLock = 0
          context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header
          context.dispatch('me', { refresh: true, useCache: false })
          context.dispatch('getOrdersHistory', { refresh: true, useCache: false })
        }
        return resp
      })
  },
  processQueryToken (context, token) {
    context.state.userTokenInvalidateLock = 0
    context.commit(types.USER_TOKEN_CHANGED, { newToken: token, meta: {} }) // TODO: handle the "Refresh-token" header
    context.dispatch('me', { refresh: true, useCache: false })
    context.dispatch('getOrdersHistory', { refresh: true, useCache: false })
  },
  /**
   * Re-send activation email
   */
  sendActivation (context, { email }) {
    return axios().post(routes.users.activate_endpoint, { email }, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
  },
  /**
   * Login user and return user profile and current token
   */
  sendFeedback (context, payload) {
    let url = routes.users.feedback_endpoint
    return axios().post(url, payload, {
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then((resp) => {
        if (resp.code === 200 && resp.result !== 'Feedback sent') {
          return true
        }
        return resp
      })
  },
  /**
   * Login user and return user profile and current token
   */
  register (context, { email, firstname, lastname, password }) {
    let url = routes.users.create_endpoint
    return axios().post(url, { customer: { email, firstname, lastname }, password }, {
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then((resp) => {
        if (resp.code === 200 && resp.result !== 'Confirmation sent') {
          context.dispatch('login', { username: email, password: password })
        }
        return resp
      })
  },

  /**
   * Invalidate user token
   */
  refresh (context) {
    return new Promise((resolve, reject) => {
      const usersCollection = Vue.prototype.$db.usersCollection
      usersCollection.getItem('current-refresh-token', (err, refreshToken) => {
        if (err) {
          console.error(err)
        }
        let url = routes.users.refresh_endpoint
        return axios().post(url, { refreshToken }, {
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        })
          .then((resp) => {
            if (resp.code === 200) {
              context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta ? resp.meta : null }) // TODO: handle the "Refresh-token" header
            }
            resolve(resp)
          }).catch((exc) => reject(exc))
      })
    })
  },
  /**
   * Update user groupToken and groupId in state
   * @param context
   * @param userData
   */
  setUserGroup (context, userData) {
    if (routes.usePriceTiers) {
      if (userData.groupToken) {
        context.commit(types.USER_GROUP_TOKEN_CHANGED, userData.groupToken)
      }

      if (userData.group_id) {
        context.commit(types.USER_GROUP_CHANGED, userData.group_id)
      }
    } else {
      context.commit(types.USER_GROUP_TOKEN_CHANGED, '')
      context.commit(types.USER_GROUP_CHANGED, null)
    }
  },

  /**
   * Load current user profile.
   *
   * @param {Vuex.Store}
   * @param {Object} [options]
   * @returns {Promise<Object, Error>}
   */
  me ({ state, commit, dispatch }, { refresh = true, useCache = true } = {}) {
    return new Promise((resolve, reject) => {
      if (!state.token) {
        console.log('No User token, user unauthorized', 'user')
        return resolve(null)
      }
      const cache = Vue.prototype.$db.usersCollection
      let resolvedFromCache = false

      // @todo Почему использование кеша происходит до принудительного обновления данных (если refresh = true)?
      if (useCache) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('current-user', (err, res) => {
          if (err) {
            console.error(err)
            return
          }

          if (res) {
            commit(types.USER_INFO_LOADED, res)
            dispatch('setUserGroup', res)
            EventBus.$emit(EVENT_USER_PROFILE_LOADED, res)
            dispatch('cart/userAfterLoggedin', null, {root: true})

            resolve(res)
            resolvedFromCache = true
            console.log('Current user served from cache')
          }
        })
      }

      if (refresh) {
        apiUser.me().then(
          user => {
            commit(types.USER_INFO_LOADED, user) // this also stores the current user to localStorage
            dispatch('setUserGroup', user)

            if (!resolvedFromCache) {
              EventBus.$emit(EVENT_USER_PROFILE_LOADED, user)
              dispatch('cart/userAfterLoggedin', null, { root: true })
              resolve(user)
            }
          },
          error => {
            reject(error)
          }
        )
      } else if (!resolvedFromCache) {
        reject(new Error('User profile not found.'))
      }
    })
  },

  /**
   * Update user profile with data from My Account page
   */
  async update (context, userData) {
    await apiUser.update(userData)
  },

  refreshCurrentUser (context, userData) {
    context.commit(types.USER_INFO_LOADED, userData)
  },
  /**
   * Change user password
   */
  changePassword (context, passwordData) {
    return axios().post(routes.users.changePassword_endpoint(context.state.token), passwordData, {
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then((resp) => {
      if (resp.code === 200) {
        context.dispatch('notification/spawnNotification', {
          type: 'success',
          message: 'Password has successfully been changed',
          action1: { label: 'OK' }
        }, {root: true})

        context.dispatch('user/login', {
          username: context.state.current.email,
          password: passwordData.newPassword
        }, {root: true})
      } else {
        context.dispatch('notification/spawnNotification', {
          type: 'error',
          message: resp.result,
          action1: { label: 'OK' }
        }, {root: true})
      }
    })
  },
  clearCurrentUser (context) {
    context.commit(types.USER_GROUP_TOKEN_CHANGED, '')
    context.commit(types.USER_GROUP_CHANGED, null)
    context.commit(types.USER_INFO_LOADED, null)
    context.dispatch('wishlist/clear', null, { root: true })
    context.dispatch('checkout/savePersonalDetails', {}, { root: true })
    context.dispatch('checkout/saveShippingDetails', {}, { root: true })
    context.dispatch('checkout/savePaymentDetails', {}, { root: true })
  },
  /**
   * Logout user
   */
  logout (context, { silent = false }) {
    context.commit(types.USER_END_SESSION)
    context.dispatch('cart/serverTokenClear', {}, { root: true })
      .then(() => { context.dispatch('clearCurrentUser') })
      .then(() => { EventBus.$emit('user-after-logout') })
      .then(() => { context.dispatch('cart/clear', {}, { root: true }) })
    if (!silent) {
      context.dispatch('notification/spawnNotification', {
        type: 'success',
        message: "You're logged out",
        action1: { label: 'OK' }
      }, {root: true})
    }
    const usersCollection = Vue.prototype.$db.usersCollection
    usersCollection.setItem('current-token', '')
  },
  /**
   * Load user's orders history
   */
  getOrdersHistory (context, { refresh = true, useCache = true }) {
    // TODO: Make it as an extension from users module
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        console.debug('No User token, user unathorized')
        return resolve(null)
      }
      const cache = Vue.prototype.$db.ordersHistoryCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('orders-history', (err, res) => {
          if (err) {
            console.error(err)
            return
          }

          if (res) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, res)
            EventBus.$emit('user-after-loaded-orders', res)

            resolve(res)
            resolvedFromCache = true
            console.log('Current user order history served from cache')
          }
        })
      }

      if (refresh) {
        return axios().get(routes.users.history_endpoint(context.state.token), {
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        }).then((resp) => {
          if (resp.code === 200) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
            EventBus.$emit('user-after-loaded-orders', resp.result)
          }
          if (!resolvedFromCache) {
            resolve(resp.code === 200 ? resp : null)
          }
          return resp
        })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },
  userAfterUpdate (context, event) {
    if (event.code === 200) {
      context.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Account data has successfully been updated',
        action1: { label: 'OK' }
      }, {root: true})
      context.dispatch('user/refreshCurrentUser', event.result, {root: true})
    }
  },
  sessionAfterAuthorized (context, event) {
    console.log('User session authorised ', 'user')
    context.dispatch('user/me', { refresh: navigator.onLine }, { root: true }).then((us) => {}) // this will load user cart
    context.dispatch('user/getOrdersHistory', { refresh: navigator.onLine }, { root: true }).then((us) => {})
  }
}

export default {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    groupToken: '',
    groupId: null,
    current: null,
    current_storecode: '',
    session_started: new Date(),
    orders_history: null
  },
  getters,
  actions,
  mutations
}

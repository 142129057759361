import axios, { routes } from '../../../../services/http'
import Cookie from 'js-cookie'

const state = {
  referralInfo: {},
  inviteInfo: {},
  invited: false,
  invitedEmail: '',
  referral: '',
  inviter: '',
  scheduledDate: null,
  busySchedule: []
}

const getters = {
  referralInfo: state => state.referralInfo,
  inviteInfo: state => state.inviteInfo,
  invited: state => !!state.invitedEmail || (state.inviteInfo && state.inviteInfo._id),
  inviter: state => state.inviter,
  invitedEmail: state => state.invitedEmail,
  scheduledDate: state => state.scheduledDate,
  busySchedule: state => state.busySchedule
}

const mutations = {
  loadInfo (state, payload) {
    state.referralInfo = payload
  },
  loadInviteInfo (state, payload) {
    state.inviteInfo = payload
  },
  setInvitedState (state, email) {
    state.invited = true
    state.invitedEmail = email
  },
  setReferral (state, code) {
    state.referral = code
  },
  setScheduledDate (state, date) {
    state.scheduledDate = date
  },
  setInviteReferral (state, code) {
    state.inviter = code
  },
  setBusySchedule (state, busySchedule) {
    state.busySchedule = busySchedule
  }
}

const actions = {
  loadCachedInviteState ({ commit, dispatch }) {
    commit('setInviteReferral', Cookie.get('referral_inviter'))
    const email = Cookie.get('referral_invited_email')
    if (email) {
      commit('setInvitedState', email)
      dispatch('requestInviteInfo', email)
    }
  },
  setScheduledDate ({ commit, state }) {
    const email = state.invitedEmail || Cookie.get('referral_invited_email')
    if (email) {
      return axios().post(routes.referral.scheduleCall, {
        date: state.scheduledDate,
        email
      }, {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      })
        .then(data => {
          if (data.code === 200) {
            console.log(data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  completeChecklist ({ commit, state }, checklist) {
    const email = checklist.email || state.invitedEmail || Cookie.get('referral_invited_email')
    if (email) {
      return axios().post(routes.referral.saveChecklist, {
        checklist,
        email
      }, {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      })
        .then(data => {
          if (data.code === 200) {
            console.log(data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  completeMeasurements ({ commit, state }, measurements) {
    const email = state.invitedEmail || Cookie.get('referral_invited_email')
    if (email) {
      return axios().post(routes.referral.saveMeasurements, {
        measurements,
        email
      }, {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      })
        .then(data => {
          if (data.code === 200) {
            console.log(data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  setInviteState ({ commit }, email) {
    Cookie.set('referral_invited_email', email)
    commit('setInvitedState', email)
  },
  setReferral ({ commit }, code) {
    Cookie.set('referral_referrer', code)
    commit('setReferral', code)
  },
  setInviteReferral ({ commit }, code) {
    Cookie.set('referral_inviter', code)
    commit('setInviteReferral', code)
  },
  requestInfo ({ commit, state }, user) {
    return axios().post(routes.referral.info, {
      user: user || null
    }, {
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          commit('loadInfo', data.result)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  requestInviteInfo ({ commit, state }, email) {
    if (email && !state.invited) {
      commit('setInvitedState', email)
    }
    return axios().post(routes.referral.inviteInfo, {
      email
    }, {
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          commit('loadInviteInfo', data.result)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  sendEmail ({ commit, state }, { user, friends }) {
    return axios().post(routes.referral.sendEmail, {
      user: user || null,
      friends
    }, {
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          return data.result
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  sendInviteEmail ({ commit, state }, friends) {
    const email = Cookie.get('referral_invited_email')
    return axios().post(routes.referral.sendInviteEmail, {
      email,
      friends
    }, {
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          return data.result
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  invite ({ commit, state }, customer) {
    const inviter = state.inviter
    return axios().post(routes.referral.inviteRequest, {
      customer,
      inviter
    }, {
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          return data.result
        }
        return data.result
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getBusySchedule ({ commit }) {
    return axios().get(routes.referral.getBusySchedule, {
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          commit('setBusySchedule', data.result)
        }
        return []
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

// Add your own plugins in this folder with Vue.use()
import Vue from 'vue'
import './components'
import * as filters from '../filters'
import { plugin as db } from '../services/cache'
// packages
import VueProgressBar from 'vue-progressbar'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import VueCarousel from 'vue-carousel'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import VueLazyload from 'vue-lazy-load'
// import VueAuthenticate from 'vue-authenticate'
import VueClipboard from 'vue-clipboard2'
import VueScrollTo from 'vue-scrollto'
import SocialSharing from 'vue-social-sharing'
import VueObserveVisibility from 'vue-observe-visibility'
import { plugin as AxiosPlugin } from '../services/http'
import { plugin as EventBusPlugin } from '../services/eventBus'

Vue.use(VueLazyload, { attempt: 2, preLoad: 1.5 })
Vue.use(AxiosPlugin)
Vue.use(EventBusPlugin)
Vue.use(VueProgressBar)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueCarousel)
Vue.use(VueClipboard)
Vue.use(SocialSharing)
Vue.use(VueObserveVisibility)
Vue.use(VueScrollTo)
// Vue.use(VueAuthenticate, authConfig(window.application.env))

Vue.use(VueProgressBar)
Vue.use(VModal, { dialog: true })
Vue.use(db)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

import Cookies from 'js-cookie'

export const utmCapture = () => {
  let STORE_COOKIES = true
  let STORE_LOCAL_STORAGE = false

  let utmCookie = {
    cookieNamePrefix: '__lt_',
    cookieCumulativePrefix: '__cu_',
    cookieNameFirstTouchPrefix: '__ft_',

    utmParams: [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ],

    cookieExpiryDays: 365,

    // From http://www.quirksmode.org/js/cookies.html
    createCookie: function (name, value, days) {
      if (STORE_COOKIES) {
        Cookies.set(name, value, { expires: days })
      }
      if (STORE_LOCAL_STORAGE) this.createLS(name, value)
    },

    createLS: function (name, value) {
      localStorage[this.cookieNamePrefix + name] = value
    },

    readCookie: function (name) {
      if (STORE_COOKIES) {
        return Cookies.get(name)
      }
      if (STORE_LOCAL_STORAGE) this.readLS(name)
    },

    readLS: function (name) {
      let val = localStorage[this.cookieNamePrefix + name]
      if (val === undefined) return null
      else return val
    },

    checkIfFirstTouch: function () {
      const name = this.cookieNameFirstTouchPrefix + 'utm_source'
      if (STORE_COOKIES) {
        return Cookies.get(name)
      }
      if (STORE_LOCAL_STORAGE) this.readLS(this.cookieNameFirstTouchPrefix + 'utm_source')
    },

    eraseCookie: function (name) {
      if (STORE_COOKIES) Cookies.remove(name)
      if (STORE_LOCAL_STORAGE) delete localStorage[name]
    },

    getParameterByName: function (name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      let regexS = '[\\?&]' + name + '=([^&#]*)'
      let regex = new RegExp(regexS)
      let results = regex.exec(window.location.search)
      if (results == null) {
        return ''
      } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '))
      }
    },

    utmPresentInUrl: function () {
      let present = false
      for (let i = 0; i < this.utmParams.length; i++) {
        let param = this.utmParams[i]
        let value = this.getParameterByName(param)
        if (value !== '' && value !== undefined) {
          present = true
        }
      }
      return present
    },

    writeUtmCookieFromParams: function () {
      for (let i = 0; i < this.utmParams.length; i++) {
        let param = this.utmParams[i]
        let value = this.getParameterByName(param)
        if (STORE_COOKIES) this.createCookie(param, value, this.cookieExpiryDays)
        if (STORE_LOCAL_STORAGE) localStorage[param] = value
      }
    },

    writeCookieOnce: function (name, value) {
      if (STORE_COOKIES && !this.readCookie(name)) {
        this.createCookie(name, value, this.cookieExpiryDays)
      }
      if (STORE_LOCAL_STORAGE && this.readLS(name) == null) {
        this.createLS(name, value)
      }
    },

    writeReferrerOnce: function () {
      let value = document.referrer
      if (value === '' || value === undefined) {
        this.writeCookieOnce('referrer', 'direct')
      } else {
        this.writeCookieOnce('referrer', value)
      }
      this.writeCookieOnce('firstContactDatetime', (new Date()).toISOString())
    },

    referrer: function () {
      return this.readCookie('referrer')
    }
  }

  if (!utmCookie.checkIfFirstTouch()) {
    utmCookie.cookieNamePrefix = utmCookie.cookieNameFirstTouchPrefix
    utmCookie.writeReferrerOnce()

    if (utmCookie.utmPresentInUrl()) {
      utmCookie.writeUtmCookieFromParams()
    }
    utmCookie.cookieNamePrefix = '__lt_'
  }

  utmCookie.writeReferrerOnce()

  if (utmCookie.utmPresentInUrl()) {
    utmCookie.writeUtmCookieFromParams()
  }
}

export const USER_TOKEN = 'user/token'

export default {
  isLoggedIn (state) {
    return state.current !== null
  },

  token (state) {
    return state.token
  }
}

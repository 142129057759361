import Vue from 'vue'
import * as types from './mutation-types'
import axios, {routes} from '../../../services/http'
const actions = {
  /**
   * Place order - send it to service worker queue
   * @param {Object} commit method
   * @param {Order} order order data to be send
   */
  async placeOrder ({ commit, dispatch, rootGetters }, order) {
    Vue.prototype.$bus.$emit('order-before-placed', { order: order })
    Vue.prototype.$bus.$emit('notification-progress-start', 'Processing order...')
    try {
      const task = await axios().post(routes.orders.endpoint(rootGetters['user/token']), order, {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      })
      Vue.prototype.$bus.$emit('notification-progress-stop')
      if (task.code !== 500) {
        order.transmited = true
        commit(types.ORDER_PLACE_ORDER, order) // archive this order but not trasmit it second time
        commit(types.ORDER_LAST_ORDER_WITH_CONFIRMATION, { order: order, confirmation: task.result })
        Vue.prototype.$bus.$emit('order-after-placed', { order: order, confirmation: task.result })
      }
      return task
    } catch (e) {
      dispatch('notification/spawnNotification', {
        type: 'error',
        message: 'The order can not be transferred because of server error. Order has been queued',
        action1: { label: 'OK' }
      }, {root: true})
      order.transmited = false // queue order
      commit(types.ORDER_PLACE_ORDER, order) // archive this order but not trasmit it second time
      Vue.prototype.$bus.$emit('notification-progress-stop')
      throw e
    }
  },
  setLastOrderData (context, order) {
    context.commit(types.ORDER_SET_LAST_DATA, order)
  }
}

export default actions

<template>
  <component
    :is="link ? 'router-link' : 'button'"
    :to="link"
    class="button-outline no-outline py15 bg-cl-transparent h4 no-underline sans-serif fs-medium"
    :class="{
      light : color === 'light', 'brdr-white' : color === 'light', 'cl-white' : color === 'light',
      dark : color === 'dark', 'brdr-darkgray' : color === 'dark', 'cl-secondary' : color === 'dark',
      px0 : !!link,
      px40 : !link
    }"
    @click="$emit('click', $event)"
  >
    <slot>
      Button
    </slot>
  </component>
</template>

<script>
import focusClean from '../directives/focusClean'

export default {
  name: 'ButtonOutline',
  directives: { focusClean },
  props: {
    color: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  $dark-border: color(secondary);
  $white: color(white);
  $black: color(black);

  .button-outline {
    border: 2px solid;
    border-radius: 3px;
    min-width: 250px;
    cursor: pointer;
  }
  .dark {
    border: 1px solid $dark-border;
    &:hover,
    &:focus {
      color: $white;
      background: $black;
      border-color: $black;
    }
  }
  .light {
    &:hover,
    &:focus {
      color: $black;
      background: $white;
      border-color: $white;
    }
  }
</style>

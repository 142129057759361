import Vue from 'vue'

const vue = new Vue({})

const EventBus = {}

EventBus.$on = function () {
  vue.$on.apply(vue, arguments)
}

EventBus.$off = function () {
  vue.$off.apply(vue, arguments)
}

EventBus.$emit = function (eventName, payload) {
  vue.$emit.apply(vue, arguments)
}

EventBus.$once = function () {
  vue.$once.apply(vue, arguments)
}

let pluginInstalled = false
export const plugin = {
  install (Vue) {
    if (!pluginInstalled) {
      Vue.prototype.$bus = EventBus
      pluginInstalled = true
    }
  }
}

export default EventBus

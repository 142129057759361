import axios, { routes } from '../../../../services/http'
import { initial, questions } from '../fixtures/questions.json'

const state = {
  answers: {},
  currentQuestion: initial,
  moods: [],
  models: [],
  colors: [],
  order: [],
  offers: [],
  combinations: [],
  previous: null,
  results: {},
  offersLoading: false,
  selectedOffer: null,
  finishedQuestionnaires: [],
  id: null
}

const getters = {
  answerByQuestionName: state => questionId => state.answers[questionId] || null,
  currentQuestion: state => state.currentQuestion,
  previousQuestion: state => state.previous,
  results: state => state.results,
  offers: state => state.offers,
  combinations: state => state.combinations,
  moods: state => state.moods,
  models: state => state.models,
  colors: state => state.colors,
  offersLoading: state => state.offersLoading,
  selectedOffer: state => state.selectedOffer,
  finishedQuestionnaires: state => state.finishedQuestionnaires,
  nextQuestion: (state, getters) => {
    const moodQuestionAnswer = getters.answerByQuestionName('question_mood')
    let next = null
    if (getters.answer && getters.answer.next) {
      next = getters.answer.next
    } else if (getters.question && getters.question.next) {
      next = getters.question.next
    }
    if (next === 'question_sleeve_type' && moodQuestionAnswer && moodQuestionAnswer.name === 'Halter Neck') {
      next = 'question_price'
    }
    return next
  },
  canCreateOffers: (state, getters) => {
    if (getters.fittingItems && Object.keys(getters.fittingItems).length) {
      return getters.fittingItems.Top && getters.fittingItems.Top.length &&
        ((getters.fittingItems.Skirt && getters.fittingItems.Skirt.length) || (getters.fittingItems.Pants && getters.fittingItems.Pants.length))
    }
    return false
  },
  isComplete: state => state.order && state.order.includes('question_8') && state.order.length > 3,
  question: state => {
    const currentQuestion = questions.find(q => q.name === (state.currentQuestion || initial)) || {}
    return {
      ...currentQuestion,
      choices: currentQuestion.choices ? currentQuestion.choices.filter(choice => {
        if (!choice['v-if']) return true
        else {
          const [comparee, comparable] = choice['v-if'].split('=')
          const comparedValue = state.answers[comparee] ? state.answers[comparee].value : state.answers[comparee]
          if (comparee && comparedValue) {
            return `'${comparedValue}'` === comparable
          }
          return false
        }
      }) : []
    }
  },
  answer: state => state.answers && state.answers[state.currentQuestion]
    ? state.answers[state.currentQuestion]
    : {},
  fittingItems: state => state.answers && state.answers['question_8'] && state.answers['question_8'].items
    ? state.answers['question_8'].items
    : {},
  paintColors: state => state.answers && state.answers['question_color']
    ? state.answers['question_color']
    : [],
  forSave: (state, getters) => {
    return state.order.map(questionName => {
      const question = questions.find(q => q.name === questionName)
      let answer = getters.answerByQuestionName(questionName)
      if (answer && Array.isArray(answer)) {
        answer = answer.map(a => {
          return a.next ? {
            ...a,
            next: null
          } : a
        })
      }
      if (answer && answer.next) {
        answer = {
          ...answer,
          next: null
        }
      }
      return {
        question: {
          ...question,
          choices: null,
          next: null
        },
        answer
      }
    })
  }
}

const mutations = {
  setAnswer (state, answer) {
    state.answers = {
      ...state.answers,
      [state.currentQuestion]: answer
    }
  },
  setAnswerByQuestionName (state, {answer, questionName}) {
    state.answers = {
      ...state.answers,
      [questionName]: answer
    }
  },
  setId (state, id) {
    state.id = id
  },
  setCombinations (state, combinations) {
    state.combinations = combinations
  },
  setOffers (state, offers) {
    state.offers = offers
  },
  setMoods (state, moods) {
    state.moods = moods
  },
  setModels (state, models) {
    state.models = models
  },
  setColors (state, colors) {
    state.colors = colors
  },
  setSelectedOffer (state, selectedOffer) {
    state.selectedOffer = selectedOffer
  },
  setOfferLoading (state, loading) {
    state.offerLoading = loading
  },
  setOffersLoading (state, loading) {
    state.offersLoading = loading
  },
  restartQuestionnaire (state) {
    state.answers = {}
    state.currentQuestion = initial
  },
  setNext (state, question) {
    state.previous = state.currentQuestion
    if (!state.order.includes(state.currentQuestion)) {
      state.order = [...(state.order || []), state.currentQuestion]
    } else {
      const index = state.order.findIndex(i => i === state.currentQuestion)
      state.order = [
        ...state.order.slice(0, index),
        state.currentQuestion
      ]
    }
    state.currentQuestion = question
  },
  setPrevious (state) {
    state.order = (state.order || []).slice(0, -1)
    state.currentQuestion = state.previous
    state.previous = state.order[state.order.length - 1]
  },
  goToQuestion (state, question) {
    if (!state.order.includes(question)) {
      state.order = []
      state.previous = null
      state.currentQuestion = initial
    } else {
      const index = state.order.findIndex(i => i === question)
      state.order = [
        ...state.order.slice(0, index),
        question
      ]
      state.previous = state.order[state.order.length - 2]
      state.currentQuestion = question
    }
  },
  loadFinishedQuestionnaires (state, data) {
    state.finishedQuestionnaires = data
  },
  newQuestionnaire (state) {
    state.answers = {}
    state.order = []
    state.previous = null
    state.id = null
    state.currentQuestion = initial
  },
  reviewFinishedQuestionnaire (state, questionnaire) {
    state.order = questionnaire.questionnaire.map(item => item.question.name)
    state.currentQuestion = state.order[0]
    state.previous = null
    state.id = questionnaire._id
    state.answers = questionnaire.questionnaire.reduce((answers, item) => {
      answers[item.question.name] = { ...item.answer }
      return answers
    }, {})
  }
}

const actions = {
  commitQuestion ({ commit, getters }) {
    if (getters.nextQuestion) {
      commit('setNext', getters.nextQuestion)
    } else {
      console.error('Next question for current is not set', getters.question, getters.answer)
    }
  },
  saveResults ({ commit, state, getters }, user) {
    return axios().post(
      state.id ? routes.survey.update(state.id) : routes.survey.create,
      {
        user,
        questionnaire: getters.forSave
      },
      {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }
    )
      .then(data => {
        if (data.code === 200) {
          commit('setId', data.result)
        }
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getOffers ({ commit, state, getters }) {
    commit('setOffersLoading', true)
    commit('setOffers', [])
    return axios().post(
      routes.survey.offers,
      {
        questionnaire: getters.forSave
      },
      {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }
    )
      .then(data => {
        if (data.code === 200) {
          commit('setOffers', data.result)
          commit('setOffersLoading', false)
        }
        return true
      })
      .catch((err) => {
        commit('setOffersLoading', false)
        console.log(err)
      })
  },
  getOffer ({ commit, state, getters }, id) {
    commit('setOfferLoading', true)
    commit('setSelectedOffer', {})
    return axios().get(
      routes.survey.offer(id),
      {
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors'
      }
    )
      .then(data => {
        if (data.code === 200) {
          commit('setSelectedOffer', data.result)
          commit('setOfferLoading', false)
        }
        return true
      })
      .catch((err) => {
        commit('setOfferLoading', false)
        console.log(err)
      })
  },
  getMoods ({ commit }) {
    return axios().get(routes.survey.moods)
      .then(data => {
        if (data.code === 200) {
          commit('setMoods', data.result)
        }
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getModels ({ commit }) {
    return axios().get(routes.survey.models)
      .then(data => {
        if (data.code === 200) {
          commit('setModels', data.result)
        }
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getColors ({ commit }) {
    return axios().get(routes.survey.colors)
      .then(data => {
        if (data.code === 200) {
          commit('setColors', data.result)
        }
        return true
      })
      .catch((err) => {
        console.log(err)
      })
  },
  uploadPicture ({ commit, state }, formData) {
    return axios().post(routes.survey.upload, formData, {
      headers: { 'Accept': 'application/json, */*', 'Content-Type': 'multipart/form-data' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200 && data.result.status) {
          return data.result.files
        }
        return []
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getFinishedQuestionnaires ({ commit, state }, user) {
    if (user && user.id) {
      return axios().get(routes.survey.list(user.id), {
        mode: 'cors'
      })
        .then(data => {
          if (data.code === 200) {
            return data.result
          }
          return []
        })
        .then(finishedQuestionnaires =>
          commit('loadFinishedQuestionnaires', finishedQuestionnaires)
        )
        .catch((err) => {
          console.log(err)
        })
    }
    return Promise.resolve([])
  },
  obtainToken (store, clientId) {
    return axios().get(routes.survey.tokenGenerate, {
      params: clientId ? { clientId } : clientId,
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          return data.result
        }
        return []
      })
  },
  completePayment ({ state }, payload) {
    return axios().post(routes.survey.paymentComplete(state.id), payload, {
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
      .then(data => {
        if (data.code === 200) {
          return data.result
        }
        return []
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'
import apiConfig from '../config/api'
import store from '../store'
import { USER_TOKEN } from '../store/modules/user/store/getters'

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl,
  timeout: apiConfig.timeout
})

axiosInstance.interceptors.request.use(config => {
  // Add user token if not set.
  if (config.url && config.url.search(/[?&]token=/i) < 0) {
    if (!config.params) {
      config.params = {}
    }
    if (!('token' in config.params)) {
      let token = store.getters[USER_TOKEN]
      if (token) {
        config.params.token = token
      }
    }
  }
  return config
})

axiosInstance.interceptors.response.use(
  // @todo Delete after refactoring API service.
  response => {
    let data = response.data
    let status = data.code

    if (status === undefined) {
      return response
    } else if (status >= 200 && status < 300) {
      if ('result' in data) {
        response.data = data.result
      }
      return response
    }
    return Promise.reject(new Error(!data.result ? data.result : `API error ${status}`))
  },

  // @todo Do refactoring. Code copied from services/http.js
  error => {
    let response = error.response
    if (response && response.data && response.data[0]) {
      if (response.data[0] === 'missing token' || response.data[0] === 'Wrong number of segments') {
        let request = response.request
        if (request && request.responseURL && !request.responseURL.includes('users/info')) {
          // @todo Action dropJwtToken not defined.
          store.dispatch('dropJwtToken').then(() => store.dispatch('info'))
        }
      }
    }
    return Promise.reject(error)
  }
)

export default {
  /**
   * @param {string} url
   * @param {Object} [options]
   * @param {CancelTokenSource} [cancelSource]
   * @returns {Promise<*, AxiosError>}
   */
  get (url, options = null, cancelSource = null) {
    return axiosInstance
      .get(url, this.makeRequestConfig(options, cancelSource))
      .catch(error => this.logError(error))
  },

  /**
   * @param {string} url
   * @param {Object} [options]
   * @param {CancelTokenSource} [cancelSource]
   * @returns {Promise<*, AxiosError>}
   */
  getData (url, options = null, cancelSource = null) {
    return this.get(url, options, cancelSource).then(({ data }) => data)
  },

  /**
   * @param {string} url
   * @param {string|Object} [data]
   * @param {Object} [options]
   * @param {CancelTokenSource} [cancelSource]
   * @returns {Promise<*, AxiosError>}
   */
  post (url, data = null, options = null, cancelSource = null) {
    return axiosInstance
      .post(url, data, this.makeRequestConfig(options, cancelSource))
      .catch(error => this.logError(error))
  },

  /**
   * @param {Object|null} options
   * @param {CancelTokenSource} [cancelSource]
   * @returns {Object|null}
   */
  makeRequestConfig (options, cancelSource = null) {
    return cancelSource ? {
      ...options,
      cancelToken: cancelSource.token
    } : options
  },

  /**
   * @param {AxiosError} error
   * @throws {AxiosError}
   */
  logError (error) {
    if (this.isErrorCancel(error)) {
      console.log(error)
    } else {
      console.error(error)
    }
    throw error
  },

  /**
   * @returns {CancelTokenSource}
   */
  getCancelTokenSource () {
    return axios.CancelToken.source()
  },

  /**
   * @param {AxiosError} error
   * @returns {boolean}
   */
  isErrorCancel (error) {
    return axios.isCancel(error)
  }
}

import pjson from '../../package.json'

export const version = pjson.version
const projectName = pjson.name

const leftPad = (str, length = 3, fill = ' ') => {
  return `${fill.toString()
    .repeat(Math.max(length - str.length, 0))}${str}`
}
const numberToVersion = (number) => {
  return leftPad(number.toString(), 3, '0')
    .split('')
    .join('.')
}
const versionToNumber = (version) => {
  return parseInt(version.toString()
    .split('.')
    .join(''), 10)
}

export const storage = {
  getItem (storageKey) {
    let state = {}
    for (let i = 0; i <= localStorage.length - 1; i++) {
      const key = localStorage.key(i)
      if (key === storageKey) {
        state = localStorage.getItem(storageKey)
        break
      }
      if (key.startsWith(storageKey) && localStorage.getItem(key)) {
        state[key.replace(storageKey + '_', '')] = JSON.parse(localStorage.getItem(key))
      }
    }
    console.log(state)
    return state
  },
  setItem (key, value) {
    try {
      if (typeof value !== 'object') {
        localStorage.setItem(key, value)
      } else {
        Object.keys(value).forEach(objKey => {
          localStorage.setItem(`${key}_${objKey}`, JSON.stringify(value[objKey]))
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  removeItem (storageKey) {
    for (let i = 0; i <= localStorage.length - 1; i++) {
      const key = localStorage.key(i)
      if (key.startsWith(storageKey) && localStorage.getItem(key)) {
        localStorage.removeItem(key)
      }
    }
  }
}

export const dropOldVersions = () => {
  if (window.localStorage && window.localStorage.removeItem) {
    const currentNumericVersion = versionToNumber(version)
    if (currentNumericVersion > 0) {
      for (let i = 0; i < currentNumericVersion; i++) {
        const pastVersion = numberToVersion(i)
        window.localStorage.removeItem(`${projectName}_${pastVersion}`)
      }
    }
  }
}

export const currentVersion = `${projectName}_${version}`

import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import EventBus from './../services/eventBus'
import store from './../store'

Vue.use(Router)

const router = new Router({
  routes,
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.onError(error => {
  if (error.message === 'Unauthorized') {
    store.commit('ui/setAuthElem', 'login')
    EventBus.$emit('modal-show', 'modal-signup')
  } else {
    console.log(error)
  }
})

export default router

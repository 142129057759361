import * as localForage from 'localforage'
import UniversalStorage from '../utils/storage'
const dbNamePrefix = 'couturme'

let pluginInstalled = false
export const plugin = {
  install (Vue) {
    if (!pluginInstalled) {
      Vue.prototype.$db = {
        currentStoreCode: 'us',
        ordersCollection: new UniversalStorage(localForage.createInstance({
          name: 'shop',
          storeName: 'orders',
          driver: localForage['LOCALSTORAGE']
        })),
        categoriesCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'categories',
          driver: localForage['LOCALSTORAGE']
        })),
        attributesCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'attributes',
          driver: localForage['LOCALSTORAGE']
        })),
        cartsCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'carts',
          driver: localForage['LOCALSTORAGE']
        })),
        elasticCacheCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'elasticCache',
          driver: localForage['LOCALSTORAGE']
        }), true, 4096),
        productsCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'products',
          driver: localForage['LOCALSTORAGE']
        })),
        claimsCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'claims',
          driver: localForage['LOCALSTORAGE']
        })),
        compareCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'compare',
          driver: localForage['LOCALSTORAGE']
        })),
        usersCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'user',
          driver: localForage['LOCALSTORAGE']
        })),
        syncTaskCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'syncTasks',
          driver: localForage['LOCALSTORAGE']
        })),
        checkoutFieldsCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'checkoutFieldValues',
          driver: localForage['LOCALSTORAGE']
        })),
        ordersHistoryCollection: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'ordersHistory',
          driver: localForage['LOCALSTORAGE']
        })),
        cmsData: new UniversalStorage(localForage.createInstance({
          name: dbNamePrefix + 'shop',
          storeName: 'cms'
        }))
      }
      pluginInstalled = true
    }
  }
}

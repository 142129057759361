<template>
  <div id="app" @contextmenu="blockRightClick">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { DEBUG } from '@/utils'
import { mapState } from 'vuex'
const DefaultLayout = () => import(/* webpackChunkName: "layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "layout-minimal" */ './layouts/Minimal')

export default {
  data () {
    return {
      ordersData: []
    }
  },
  methods: {
    blockRightClick ($event) {
      if (!DEBUG) {
        $event.preventDefault()
        $event.cancelBubble = true
        return false
      }
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout
  }
}
</script>

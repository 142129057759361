import { stringify } from 'qs'
import axios from 'axios'
import api from './../config/api'
import store from './../store'
import { USER_TOKEN } from '../store/modules/user/store/getters'

let pluginInstalled = false

const getAxiosInstance = () => {
  const axiosInstance = axios.create()
  axiosInstance.defaults.baseURL = api.baseUrl
  axiosInstance.interceptors.request.use((config) => {
    // Add user token if not set.
    if (config.url && config.url.search(/[?&]token=/i) < 0) {
      if (!config.params) {
        config.params = {}
      }
      if (!('token' in config.params)) {
        let token = store.getters[USER_TOKEN]
        if (token) {
          config.params.token = token
        }
      }
    }

    if (config.method === 'post' &&
      (
        !config.headers['Content-Type'] ||
        config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
      )
    ) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = stringify(config.data)
    }
    return config
  })

  axiosInstance.interceptors.response.use((response) => {
    return response.data
  }, (error) => {
    if (error.response && error.response.data && error.response.data[0]) {
      if (error.response.data[0] === 'missing token' || error.response.data[0] === 'Wrong number of segments') {
        if (error.response && error.response.request && error.response.request.responseURL && !error.response.request.responseURL.includes('users/info')) {
          store.dispatch('dropJwtToken')
            .then(() => store.dispatch('info'))
        }
      }
    }
    return Promise.reject(error)
  })
  return axiosInstance
}

export const plugin = {
  install (Vue) {
    if (!pluginInstalled) {
      Vue.axios = axios
      Object.defineProperties(Vue.prototype, {
        axios: {
          get () {
            return getAxiosInstance(store.commit)
          }
        },
        $http: {
          get () {
            return getAxiosInstance(store.commit)
          }
        }
      })
      pluginInstalled = true
    }
  }
}

export const routes = api.routes
export default getAxiosInstance

// Add your own plugins in this folder with Vue.use()
import Vue from 'vue'
import MdIcon from '../components/MdIcon'
import ButtonFull from '../components/ButtonFull'
import ButtonOutline from '../components/ButtonOutline'
import BaseCheckbox from '../components/blocks/Form/BaseCheckbox'
import BaseInput from '../components/blocks/Form/BaseInput'
import BaseRadiobutton from '../components/blocks/Form/BaseRadiobutton'
import BaseSelect from '../components/blocks/Form/BaseSelect'
import BaseTextarea from '../components/blocks/Form/BaseTextarea'

Vue.component('md-icon', MdIcon)
Vue.component('button-full', ButtonFull)
Vue.component('button-outline', ButtonOutline)
Vue.component('base-checkbox', BaseCheckbox)
Vue.component('base-input', BaseInput)
Vue.component('base-radiobutton', BaseRadiobutton)
Vue.component('base-select', BaseSelect)
Vue.component('base-textarea', BaseTextarea)

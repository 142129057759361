export default {
  // Base URL of the API service.
  baseUrl: '/api',

  // Maximum wait time for an API response in milliseconds.
  timeout: 30000,

  routes: {
    auth: {
      check: '/ext/users/check',
      info: '/ext/users/info',
      paymentInfo: '/ext/users/info/billing',
      accountInfo: '/ext/users/account_info',
      login: '/ext/users/login',
      logout: '/ext/users/logout',
      register: '/ext/users/register',
      update: '/ext/users/update',
      changePass: '/ext/users/change_password',
      resetPass: '/ext/users/reset_password',
      removePic: '/ext/users/remove_pic',
      uploadPic: '/ext/users/upload',
      updateCC: '/ext/users/update_cc',
      newsletter: '/ext/users/subscribe',
      callback: '/ext/users/add_callback',
      agreeToUse3d: '/ext/users/agree_to_use_3d',
      resendConfirm: '/ext/users/confirmation/resend'
    },
    survey: {
      upload: '/ext/survey/upload',
      update: (id) => `/ext/survey/update/${id}`,
      create: '/ext/survey/create',
      offers: '/ext/survey/offers',
      offer: id => `/ext/survey/offer/${id}`,
      moods: '/ext/survey/mood-boards',
      models: '/ext/survey/models',
      colors: '/ext/survey/colors',
      list: userId => `/ext/survey/list/${userId}`,
      tokenGenerate: '/ext/survey/payment/generate-token',
      paymentComplete: (id) => `/ext/survey/payment/complete${(id ? '/' + id : '')}`
    },
    referral: {
      scheduleCall: '/ext/referral/schedule_call',
      saveChecklist: '/ext/referral/save-checklist',
      saveMeasurements: '/ext/referral/save-measurements',
      info: '/ext/referral/info',
      inviteInfo: '/ext/referral/invite-info',
      sendEmail: '/ext/referral/send-email',
      sendInviteEmail: '/ext/referral/send-invite-email',
      inviteRequest: '/ext/referral/invite-request',
      getBusySchedule: '/ext/referral/busy-schedule'
    },
    users: {
      autoRefreshTokens: true,
      endpoint: '/user',
      history_endpoint: token => `/user/order-history?token=${token}`,
      resetPassword_endpoint: '/user/reset-password',
      changePassword_endpoint: token => `/user/change-password?token=${token}`,
      login_endpoint: '/user/login',
      create_endpoint: '/user/create',
      activate_endpoint: '/user/activate',
      feedback_endpoint: '/user/feedback',
      me: '/user/me',
      refresh_endpoint: '/user/refresh'
    },
    cart: {
      bypassCartLoaderForAuthorizedUsers: false,
      multisiteCommonCart: false,
      serverMergeByDefault: true,
      serverSyncCanRemoveLocalItems: false,
      serverSyncCanModifyLocalItems: false,
      synchronize: true,
      synchronize_totals: true,
      setCustomProductOptions: true,
      setConfigurableProductOptions: true,
      askBeforeRemoveProduct: true,
      create_endpoint: token => `/cart/create?token=${token}`,
      updateitem_endpoint: (token, cartId) => `/cart/update?token=${token}&cartId=${cartId}`,
      deleteitem_endpoint: (token, cartId) => `/cart/delete?token=${token}&cartId=${cartId}`,
      pull_endpoint: (token, cartId) => `/cart/pull?token=${token}&cartId=${cartId}`,
      totals_endpoint: (token, cartId) => `/cart/totals?token=${token}&cartId=${cartId}`,
      paymentmethods_endpoint: (token, cartId) => `/cart/payment-methods?token=${token}&cartId=${cartId}`,
      shippingmethods_endpoint: (token, cartId) => `/cart/shipping-methods?token=${token}&cartId=${cartId}`,
      shippinginfo_endpoint: (token, cartId) => `/cart/shipping-information?token=${token}&cartId=${cartId}`,
      collecttotals_endpoint: (token, cartId) => `/cart/collect-totals?token=${token}&cartId=${cartId}`,
      deletecoupon_endpoint: (token, cartId) => `/cart/delete-coupon?token=${token}&cartId=${cartId}`,
      applycoupon_endpoint: (token, cartId, coupon) => `/cart/apply-coupon?token=${token}&cartId=${cartId}&coupon=${coupon}`
    },
    orders: {
      endpoint: token => `/order?token=${token}`
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { utmCapture } from './services/utmCapture'
import { initTrackingScripts } from './utils/analytics'
import './plugins'
import 'intersection-observer'
import './css/main.scss'
import './services/pasive-event-listeners'

Vue.config.productionTip = false

/* eslint-disable-next-line no-new */
const root = new Vue({
  store,
  router,
  render: h => h(App),
  // used by vue-cli-plugin-prerender-spa package
  // config: ../vue.config.js -> pluginOptions.prerenderSpa
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
})

store.dispatch('user/me', {})

if (process.env.NODE_ENV === 'development') {
  console.log('[APP] logger in dev mode')
}
try {
  // eslint-disable-next-line
  // if (APP_REV_VERSION) {
  //   // eslint-disable-next-line
  //   window.APP_REV_VERSION = APP_REV_VERSION
  //   // eslint-disable-next-line
  //   console.log('[APP] current version', window.APP_REV_VERSION)
  // }
  // // eslint-disable-next-line
  // if (APP_REV_BRANCH) {
  //   // eslint-disable-next-line
  //   window.APP_REV_BRANCH = APP_REV_BRANCH
  //   // eslint-disable-next-line
  //   console.log('[APP] current branch', window.APP_REV_BRANCH)
  // }
  initTrackingScripts()
} catch (e) {
  console.log(e.message)
}

document.addEventListener('DOMContentLoaded', () => {
  utmCapture()
  root.$mount('#app')
})

import adapter from './adapter'
import config from '../config/api'
import { resources } from 'Api/config/api_routes.json'

const routes = config.routes.users

export default Object.assign(Object.create(adapter), {
  /**
   * Returns current user profile.
   *
   * @returns {Promise<Object, AxiosError|Error>}
   */
  me () {
    return this.getData(resources.users.me)
  },

  /**
   * Updates the current user profile.
   *
   * @param {Object} userData
   * @returns {Promise<Object, AxiosError>}
   */
  update (userData) {
    return this.post(routes.me, userData)
  }
})

export const cmToRightUnits = (input, mode) => {
  const val = parseFloat(input) || 0
  return +(mode.toLowerCase() === 'inch'
    ? +val / 2.54
    : +val).toFixed(2)
}

export const unitsInputToCm = (input, mode, precise = false) => {
  const val = parseFloat(input) || 0
  const preciseValue = +(mode.toLowerCase() === 'inch'
    ? +val * 2.54
    : +val)
  return precise
    ? preciseValue
    : preciseValue.toFixed(2)
}

export const inputNumericValue = input =>
  parseFloat((input || 0).toString()
    .replace(/[^-.,\d]/g, '')
    .replace(',', '.')
    .replace(/,*/g, ''))

export const capitalizeWords = str => {
  const word = []

  for (let char of str.trim().split(' ')) {
    word.push(char[0].toUpperCase() + char.slice(1))
  }

  return word.join(' ')
}

const identity = a => b => a === b

export const uniqBy = (comparator = identity) => (item, pos, arr) => {
  const foundPos = arr.findIndex(comparator(item))
  return foundPos === pos
}

export const uniq = uniqBy(identity)

export const DEBUG = process.env.NODE_ENV === 'development' || (window ? window.DEBUG : false)

export const log = DEBUG
  ? console.log.bind(window
    ? window.console
    : console)
  : () => {
  }

export const group = DEBUG
  ? console.group.bind(window
    ? window.console
    : console)
  : () => {
  }
export const groupCollapsed = DEBUG
  ? console.groupCollapsed.bind(window
    ? window.console
    : console)
  : () => {
  }
export const groupEnd = DEBUG
  ? console.groupEnd.bind(window
    ? window.console
    : console)
  : () => {
  }

export const cloneObject = o => JSON.parse(JSON.stringify(o))

export const requestAnimationFrame = (cb) => {
  if (window && window.requestAnimationFrame) {
    return () => window.requestAnimationFrame(cb)
  } else {
    return () => setTimeout(cb, 0)
  }
}

export const dataURItoBlob = (dataURI) => {
  let byteString = atob(dataURI.split(',')[1])

  let mimeString = dataURI.split(',')[0].split(':')[1].split('')[0]

  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export const loadScript = (url) => {
  return new Promise((resolve, reject) => {
    var head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.async = true
    script.src = url
    script.charset = 'utf8'

    head.appendChild(script)

    script.onload = resolve
    script.onerror = reject
  })
}
/**
 * Get path to default model picture
 * @param {String} side Back or front
 * @param {{name: String}} modelAnswer Model selection answer
 */
export const defaultModelPicture = (side, modelAnswer) => {
  if (side === 'front') {
    if (modelAnswer && modelAnswer.name === 'Women Plus Size') {
      return '/questionnaire/model_plus.png'
    }
  }
  if (side === 'back') {
    if (modelAnswer && modelAnswer.name === 'Women Plus Size') {
      return '/questionnaire/model_back_plus.png'
    }
    return '/questionnaire/model_back.png'
  }
  return '/questionnaire/model.png'
}

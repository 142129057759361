import { getField, updateField } from 'vuex-map-fields'
import Vue from 'vue'
import UUID from 'uuid/v4'

export default {
  namespaced: true,
  state: {
    cart: [],
    orderPlaced: false,
    personalDetails: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      rPassword: ''
    },
    shippingMethods: [
      {
        'method_title': 'Standard 5-7 day shipping',
        'method_code': 'FedEx',
        'carrier_code': 'FedEx',
        'amount': 0,
        'price_incl_tax': 0,
        'default': true,
        'offline': true
      },
      {
        'method_title': 'Express 2-day shipping',
        'method_code': 'FedEx2Day',
        'carrier_code': 'FedEx2Day',
        'amount': 50,
        'price_incl_tax': 50,
        'default': true,
        'offline': true
      }
    ],
    shippingDetails: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      country: '',
      zipCode: '',
      state: '',
      phoneNumber: '',
      shippingMethod: ''
    },
    paymentDetails: {
      firstName: '',
      lastName: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      phoneNumber: '',
      company: '',
      taxId: '',
      paymentMethod: ''
    },
    orderReview: {
      terms: false
    },
    showThankYouPage: false,
    paymentMethods: [{
      name: 'Paypal',
      code: 'PP'
    }],
    uuid: UUID()
  },

  getters: {
    getField,
    getCart: state => state.cart.map(p => ({
      ...p,
      price: p.item.price,
      sku: p.item.sku || UUID(),
      subtotal: +p.item.price * p.qty
    })),
    getCartTotal: (state, getters) => {
      let total = 0
      state.cart.map(p => {
        total += +p.item.price * p.qty
      })
      total += +getters.shippingMethodPrice
      return total
    },
    shippingMethodPrice: state => {
      if (!state.shippingDetails.shippingMethod) return 0
      const selectedShippingMethod = state.shippingMethods.find(m => m.method_code === state.shippingDetails.shippingMethod)
      return selectedShippingMethod ? selectedShippingMethod.amount : 0
    },
    getCartUuid: state => {
      return state.uuid
    },
    getTotalQuantity: state => {
      return state.cart.reduce((qty, item) => qty + item.qty, 0)
    },
    personalDetails: state => ({
      ...(state.personalDetails || {})
    }),
    shippingDetails: state => ({
      ...(state.shippingDetails || {})
    }),
    paymentDetails: state => ({
      ...(state.paymentDetails || {})
    }),
    orderReview: state => ({
      ...(state.orderReview || {})
    })
  },

  mutations: {
    setCartItems: (state, payload) => {
      state.cart = payload || []
    },
    add: (state, payload) => {
      state.cart.push({
        qty: 1,
        item: payload.item,
        price: payload.item.price,
        subtotal: payload.item.price
      })
    },
    remove: (state, id) => {
      const productIndex = state.cart.findIndex(i => i.item.id === id)
      state.cart = [
        ...state.cart.slice(0, productIndex),
        ...state.cart.slice(productIndex + 1)
      ]
    },
    increment: (state, id) => {
      const productIndex = state.cart.findIndex(i => i.item.id === id)
      state.cart = [
        ...state.cart.slice(0, productIndex),
        {
          ...state.cart[productIndex],
          qty: state.cart[productIndex].qty + 1,
          subtotal: state.cart[productIndex].price * (state.cart[productIndex].qty + 1)
        },
        ...state.cart.slice(productIndex + 1)
      ]
    },
    decrement: (state, id) => {
      const productIndex = state.cart.findIndex(i => i.item.id === id)
      if (state.cart[productIndex].qty - 1) {
        state.cart = [
          ...state.cart.slice(0, productIndex),
          {
            ...state.cart[productIndex],
            qty: state.cart[productIndex].qty - 1,
            subtotal: state.cart[productIndex].price * (state.cart[productIndex].qty - 1)
          },
          ...state.cart.slice(productIndex + 1)
        ]
      } else {
        state.cart = [
          ...state.cart.slice(0, productIndex),
          ...state.cart.slice(productIndex + 1)
        ]
      }
    },
    change: (state, id) => {
      const productIndex = state.cart.findIndex(i => i.item.id === id)
      state.cart = [
        ...state.cart.slice(0, productIndex),
        {
          ...state.cart[productIndex],
          subtotal: state.cart[productIndex].price * state.cart[productIndex].qty
        },
        ...state.cart.slice(productIndex + 1)
      ]
    },
    refresh: (state) => {
      state.cart = state.cart.map(p => ({
        ...p,
        price: p.item.price,
        subtotal: p.item.price * p.qty
      }))
    },
    setPersonalDetails: (state, personalDetails) => {
      state.personalDetails = {
        ...personalDetails
      }
    },
    setShippingDetails: (state, shippingDetails) => {
      state.shippingDetails = {
        ...shippingDetails
      }
    },
    setPaymentDetails: (state, paymentDetails) => {
      state.paymentDetails = {
        ...paymentDetails
      }
    },
    setOrderReview: (state, orderReview) => {
      state.orderReview = {
        ...orderReview
      }
    },
    updateField,
    setShowThankYouPage: (state, show) => {
      state.showThankYouPage = show
    },
    dropPassword: (state) => {
      state.personalDetails.password = ''
      state.personalDetails.rPassword = ''
    },
    clear: (state) => {
      state.cart = []
      state.uuid = UUID()
      state.orderPlaced = false
      state.personalDetails = {}
      state.shippingDetails = {}
      state.paymentDetails = {}
      state.orderReview = {}
      state.showThankYouPage = false
    }
  },

  actions: {
    add ({ commit }, payload) {
      commit('add', payload)
    },
    increment ({ state, commit }, id) {
      commit('increment', id)
    },
    decrement ({ state, commit }, id) {
      commit('decrement', id)
    },
    change ({ state, commit }, id) {
      commit('change', id)
    },
    clear ({ commit }) {
      commit('clear')
    },
    refreshTotals ({ commit }) {
      commit('refresh')
    },
    placeOrder (context, { order }) {
      try {
        return context.dispatch('order/placeOrder', order, {root: true}).then(result => {
          if (!result.resultCode || result.resultCode === 200) {
            Vue.prototype.$db.usersCollection.setItem('last-cart-bypass-ts', new Date().getTime())
            context.dispatch('clear')
            if (context.state.personalDetails.createAccount) {
              context.commit('dropPassword')
            }
          }
        })
      } catch (e) {
        if (e.name === 'ValidationError') {
          console.error('Internal validation error; Order entity is not compliant with the schema', e.messages)
          context.dispatch('notification/spawnNotification', {
            type: 'error',
            message: 'Internal validation error. Please check if all required fields are filled in. Please contact us on contributors@vuestorefront.io',
            action1: { label: 'OK' }
          }, { root: true })
        } else {
          console.error(e)
        }
      }
    },
    savePersonalDetails ({ commit }, personalDetails) {
      // todo: create and move perdonal details vuex
      commit('setPersonalDetails', personalDetails)
    },
    saveShippingDetails ({ commit }, shippingDetails) {
      // todo: move to shipping vuex
      commit('setShippingDetails', shippingDetails)
    },
    savePaymentDetails ({ commit }, paymentDetails) {
      // todo: move to payment vuex
      commit('setPaymentDetails', paymentDetails)
    },
    load ({ commit }) {
      Vue.prototype.$db.checkoutFieldsCollection.getItem('personal-details', (err, details) => {
        if (err) throw new Error(err)
        if (details) {
          commit('setPersonalDetails', details)
        }
      })
      Vue.prototype.$db.checkoutFieldsCollection.getItem('shipping-details', (err, details) => {
        if (err) throw new Error(err)
        if (details) {
          commit('setShippingDetails', details)
        }
      })
      Vue.prototype.$db.checkoutFieldsCollection.getItem('payment-details', (err, details) => {
        if (err) throw new Error(err)
        if (details) {
          commit('setPaymentDetails', details)
        }
      })
    },
    setThankYouPage ({ commit }, payload) {
      commit('setShowThankYouPage', payload)
    },
    userAfterLoggedin (context) {
      console.log('Cart reloaded')
      context.commit('refresh')
    },
    serverTokenClear (context) {
      context.commit('clear')
    }
  }
}

const PIXEL_ID = '646911178729594'
const METRIKA_ID = 52136818
const GA_ID = 'UA-133424176-1'

export const isMainHost = () => window && window.location && window.location.hostname && (window.location.hostname === 'couturme.com'/* || true */)

const methodExists = (globalVariable, signature, innerSignature) => {
  try {
    if (signature && innerSignature) {
      return window && window[globalVariable] &&
        window[globalVariable][signature] &&
        window[globalVariable][signature][innerSignature] &&
        typeof window[globalVariable][signature][innerSignature] === 'function'
    }
    if (!signature) {
      return window && window[globalVariable] && typeof window[globalVariable] === 'function'
    }
    return window && window[globalVariable] && window[globalVariable][signature] && typeof window[globalVariable][signature] === 'function'
  } catch (e) {
    console.log('[TRACK] Check method fatal', e)
    return false
  }
}

const trackMixpanelPage = url => {
  if (methodExists('mixpanel', 'track')) {
    window.mixpanel.track('PageView', { url })
  }
}

const trackFbPixelPage = url => {
  if (methodExists('fbq')) {
    window.fbq('PageView', url)
  }
}

const trackGoogleAnalyticsPage = url => {
  if (methodExists('ga')) {
    window.ga('set', 'page', url)
    window.ga('send', 'pageview')
  }
}

export const trackEvent = (...args) => {
  if (typeof window === 'undefined') {
    return
  }
  if (isMainHost()) {
    console.log('[SEO] ', ...args)
    if (methodExists('ga')) {
      window.ga('send', 'event', ...args)
    }
    if (methodExists('ym')) {
      window.ym(METRIKA_ID, 'rea', ...args)
    }
    if (methodExists('mixpanel', 'track')) {
      window.mixpanel.track(args[0], {
        ...(args[1]
          ? { action: args[1] }
          : null),
        ...(args[2]
          ? { label: args[2] }
          : null),
        ...(args[3]
          ? { value: args[3] }
          : null),
        ...(args[4]
          ? args[4]
          : null)
      })
    }
    if (methodExists('fbq')) {
      window.fbq('trackCustom', args[0], {
        ...(args[1]
          ? { action: args[1] }
          : null),
        ...(args[2]
          ? { label: args[2] }
          : null),
        ...(args[3]
          ? { value: args[3] }
          : null),
        ...(args[4]
          ? args[4]
          : null)
      })
    }
  }
}

export const trackPage = (url) => {
  if (typeof window === 'undefined') {
    return
  }
  if (isMainHost()) {
    trackMixpanelPage(url)
    trackFbPixelPage(url)
    trackGoogleAnalyticsPage(url)
  }
}

const getCurrentPage = () => window && window.location && window.location.path ? window.location.path : '/'

const trackYandexMetrikaPage = url => {
  if (methodExists('ym')) {
    window.ym(METRIKA_ID, 'hit', url)
  }
}
export const trackTransaction = (...args) => {
  if (typeof window === 'undefined') {
    return
  }
  if (isMainHost()) {
    if (methodExists('ga')) {
      window.ga('ecommerce:addTransaction', ...args)
    }
  }
}

/**
 * Send analytic events on user login
 * @param {{
 *   id,
 *   email,
 *   plan,
 *   firstName,
 *   lastName,
 *   gender,
 *   phone,
 *   purpose,
 *   createdAt,
 *   mixpanelId
 * }} userData
 */
export const trackLogin = userData => {
  const mixpanelUserData = {
    '$email': userData.email,
    '$created': userData.createdAt,
    '$first_name': userData.firstName,
    '$last_name': userData.lastName,
    '$phone': userData.phone,
    'purpose': userData.purpose,
    'Plan': userData.plan,
    'Name': `${userData.firstName} ${userData.lastName}`,
    'Id': userData.id
  }
  if (isMainHost()) {
    if (methodExists('mixpanel', 'identify')) {
      window.mixpanel.identify(userData.id)
    }
    if (methodExists('mixpanel', 'people', 'set')) {
      window.mixpanel.people.set(mixpanelUserData)
    }
    if (methodExists('ga')) {
      window.ga('set', 'userId', userData.id)
    }
    if (methodExists('ym')) {
      window.ym(METRIKA_ID, 'setUserId', userData.id)
      window.ym(METRIKA_ID, 'userParams', {
        'email': userData.email,
        'plan': userData.plan,
        'purpose': userData.purpose,
        'phone': userData.phone,
        'name': `${userData.firstName} ${userData.lastName}`,
        'gender': userData.gender
      })
    }
    if (methodExists('fbq')) {
      try {
        window.fbq('init', PIXEL_ID, { uid: userData.id })
        window.fbq('setUserProperties', PIXEL_ID, {
          'email': userData.email,
          'plan': userData.plan,
          'purpose': userData.purpose,
          '$phone': userData.phone,
          '$name': `${userData.firstName} ${userData.lastName}`,
          '$gender': userData.gender
        })
      } catch (e) {
        console.log('[TRACK] failed to auth fb user', e)
      }
    }
  } else {
    console.log('[ANALYTICS] will be sent to mixpanel', mixpanelUserData)
  }
}

export const identifyUser = (id) => {
  if (isMainHost()) {
    if (methodExists('mixpanel', 'alias')) {
      window.mixpanel.alias(id)
    }
    if (methodExists('ga')) {
      window.ga('set', 'userId', id)
    }
    if (methodExists('ym')) {
      window.ym(METRIKA_ID, 'setUserId', id)
    }
    if (methodExists('fbq')) {
      try {
        window.fbq('init', PIXEL_ID, { uid: id })
      } catch (e) {
      }
    }
  }
}

export const trackLogout = () => {
  if (isMainHost()) {
    if (methodExists('mixpanel', 'reset')) {
      window.mixpanel.reset()
    }
    if (methodExists('ga')) {
      window.ga('set', 'userId', null)
    }
    if (methodExists('ym')) {
      window.ym(METRIKA_ID, 'setUserId', null)
    }
    if (methodExists('fbq')) {
      window.fbq('init', PIXEL_ID, { uid: null })
    }
  }
}

export const trackSignUp = () => {
  if (isMainHost()) {
    if (methodExists('ga')) {
      window.ga('set', 'userId', null)
    }
    if (methodExists('ym')) {
      window.ym(METRIKA_ID, 'setUserId', null)
    }
    if (methodExists('fbq')) {
      window.fbq('init', PIXEL_ID, { uid: null })
    }
  }
}

export const initTrackingScripts = () => {
  if (typeof window === 'undefined') {
    return
  }
  if (isMainHost()) {
    Promise.all([
      import(/* webpackChunkName: "analytics" */'./google')
        .then(analytics => analytics.init())
        .then(() => {
          if (methodExists('ga')) {
            window.ga('create', GA_ID)
            window.ga('set', 'transport', 'beacon')
            window.ga('require', 'ecommerce')
            trackGoogleAnalyticsPage(getCurrentPage())
          }
        })
        .catch(e => {
          console.log('[TRACK] failed to initialize google analytics', e)
        }),
      import(/* webpackChunkName: "analytics" */'./yandex')
        .then(analytics => analytics.init())
        .then(() => {
          if (methodExists('ym')) {
            window.ym(METRIKA_ID, 'init', {
              id: METRIKA_ID,
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            })
            trackYandexMetrikaPage(getCurrentPage())
          }
        })
        .catch(e => {
          console.log('[TRACK] failed to initialize yandex metrics', e)
        }),
      import(/* webpackChunkName: "analytics" */'./fbPixel')
        .then(analytics => analytics.init())
        .then(() => {
          if (methodExists('fbq')) {
            window.fbq('init', PIXEL_ID)
            trackFbPixelPage(getCurrentPage())
          }
          return Promise.resolve()
        })
        .catch(e => {
          console.log('[TRACK] failed to initialize fbPixel', e)
        })
    ]).then(() => {
      trackErrors()
    }).catch((err) => {
      console.log('[TRACK] initialization error', err)
      trackErrors()
    })
  }
}

const trackErrors = () => {
  const loadErrorEvents = window.__e ? window.__e.q : []
  const fieldsObj = { eventAction: 'uncaught error' }

  // Replay any stored load error events.
  for (let event of loadErrorEvents) {
    trackError(event.error, fieldsObj)
  }

  // Add a new listener to track event immediately.
  window.addEventListener('error', (event) => {
    trackError(event.error, fieldsObj)
  })
}

const trackError = (error, fieldsObj = {}) => {
  if (isMainHost()) {
    if (methodExists('ga')) {
      window.ga('send', 'event', Object.assign({
        eventCategory: 'Script',
        eventAction: 'error',
        eventLabel: (error && error.stack) || '(not set)',
        nonInteraction: true
      }, fieldsObj))
    }
  }
}

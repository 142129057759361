import EventBus from '../services/eventBus.js'
import store from '../store'
import Cookie from 'js-cookie'
const Home = () => import(/* webpackChunkName: "cme-home" */ '../pages/HomeNew.vue')
const PageNotFound = () => import(/* webpackChunkName: "cme-not-found" */ '../pages/PageNotFound.vue')
const ErrorPage = () => import(/* webpackChunkName: "cme-error" */ '../pages/Error.vue')
const Checkout = () => import(/* webpackChunkName: "cme-checkout" */ '../pages/Checkout.vue')
const MyAccount = () => import(/* webpackChunkName: "cme-my-account" */ '../pages/MyAccount.vue')
const Static = () => import(/* webpackChunkName: "cme-static" */ '../pages/Static.vue')
const Questionnaire = () => import(/* webpackChunkName: "questionnaire" */ '../pages/Questionnaire.vue')
const QuestionnairePayment = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Payment.vue')
const QuestionnaireThankYouPage = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/ThankYou.vue')
const QuestionnaireOffers = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Offers.vue')
const QuestionnaireOfferDetailed = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/OfferDetailed.vue')
const ConsultationOnlineChecklist = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Simple/Checklist.vue')
const ConsultationOnlineVision = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Simple/Vision.vue')
const ConsultationOnlineMeasurements = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Simple/Measurements.vue')
const ConsultationOnlineThankYou = () => import(/* webpackChunkName: "questionnaire" */ '../components/blocks/Questionnaire/Simple/ThankYou.vue')

let routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    alias: '/pwa.html'
  },
  {
    path: '/consultation/schedule',
    component: Home,
    name: 'appointment'
  },
  { name: 'checkout', path: '/checkout', component: Checkout },
  { name: 'privacy', path: '/privacy', component: Static, props: { page: 'lorem', title: 'Privacy policy' }, meta: { title: 'Privacy Policy', description: 'COUTURME project Privacy Policy' } },
  { name: 'technology', path: '/technology', component: Static, props: { page: 'technology', title: 'Technology' } },
  { name: 'my-account', path: '/my-account', component: MyAccount, meta: { requiresAuth: true } },
  { name: 'my-shipping-details', path: '/my-account/shipping-details', component: MyAccount, props: { activeBlock: 'MyShippingDetails' }, meta: { requiresAuth: true } },
  { name: 'my-newsletter', path: '/my-account/newsletter', component: MyAccount, props: { activeBlock: 'MyNewsletter' }, meta: { requiresAuth: true } },
  { name: 'my-orders', path: '/my-account/orders', component: MyAccount, props: { activeBlock: 'MyOrders' }, meta: { requiresAuth: true } },
  { name: 'my-order', path: '/my-account/orders/:orderId', component: MyAccount, props: { activeBlock: 'MyOrder' }, meta: { requiresAuth: true } },
  { name: 'my-referral', path: '/my-account/referral', component: MyAccount, props: { activeBlock: 'MyReferralProgram' }, meta: { requiresAuth: true } },
  { name: 'my-questionnaires', path: '/my-account/questionnaire', component: MyAccount, props: { activeBlock: 'MyQuestionnaire' }, meta: { requiresAuth: true } },
  { name: 'design-studio', path: '/my-account/design-studio', component: MyAccount, props: { activeBlock: 'DesignStudio' }, meta: { requiresAuth: true } },
  { name: 'faq', path: '/faq', component: Static, props: { page: 'lorem', title: 'Faq' } },
  { name: 'about', path: '/about', component: Static, props: { page: 'lorem', title: 'Our Mission' } },
  { name: 'page-not-found', path: '/page-not-found', component: PageNotFound },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'minimal' } },
  { name: 'referral_page',
    path: '/r/:code',
    beforeEnter (to, from, next) {
      if (to.params.code && typeof window !== 'undefined') {
        store.dispatch('referral/setReferral', to.params.code)
        store.commit('ui/setAuthElem', 'login')
        EventBus.$emit('modal-toggle', 'modal-signup')
      }
      next('/')
    }
  },
  { name: 'confirm_page',
    path: '/a/:token',
    beforeEnter (to, from, next) {
      if (to.params.token && typeof window !== 'undefined') {
        store.dispatch('user/processQueryToken', to.params.token)
        next(Cookie.get('page-before-register'))
      }
      next('/my-account')
    }
  },
  { name: 'referral_invite_page',
    path: '/invite/:code',
    beforeEnter (to, from, next) {
      if (to.params.code) {
        store.dispatch('referral/setInviteReferral', to.params.code)
          .then(() => {
            setTimeout(() => EventBus.$emit('modal-toggle', 'modal-invite'), 1500)
            next('/')
          })
          .catch(() => next('/'))
      } else {
        next('/')
      }
    }
  },
  {
    path: '/questionnaire',
    name: 'questionnaire',
    alias: '/questionnaire.html',
    component: Questionnaire
  },

  {
    name: 'questionnaire_payment',
    path: '/questionnaire/style-fee',
    component: QuestionnairePayment
  },

  {
    name: 'questionnaire_offers',
    path: '/questionnaire/offers',
    component: QuestionnaireOffers
  },

  {
    name: 'questionnaire_offer_detailed',
    path: '/questionnaire/detailed',
    component: QuestionnaireOfferDetailed
  },

  {
    name: 'questionnaire_offer_by_id',
    path: '/questionnaire/offer/:id',
    beforeEnter (to, from, next) {
      if (to.params.id) {
        store.dispatch('questionnaire/getOffer', to.params.id)
          .then(() => next())
          .catch(err => {
            console.log(err)
            next('/questionnaire/offers')
          })
      } else {
        next('/questionnaire/offers')
      }
    },
    component: QuestionnaireOfferDetailed
  },

  {
    name: 'questionnaire_end',
    path: '/questionnaire/thank-you',
    component: QuestionnaireThankYouPage
  },

  {
    name: 'consultation_checklist',
    path: '/consultation/checklist',
    component: ConsultationOnlineChecklist
  },

  {
    name: 'consultation_vision',
    path: '/consultation/vision',
    component: ConsultationOnlineVision
  },

  {
    name: 'consultation_measurements',
    path: '/consultation/measurements',
    component: ConsultationOnlineMeasurements
  },

  {
    name: 'consultation_thank_you',
    path: '/consultation/thank-you',
    component: ConsultationOnlineThankYou
  }
]

export default routes

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import cart from './modules/cart/cart'
import user from './modules/user/store'
import referral from './modules/referral/store'
import questionnaire from './modules/questionnaire/store'
import notification from './modules/notification/store'
import order from './modules/order'
import ui from './modules/ui/store'
import { dropOldVersions, currentVersion } from '../services/version'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
const persistState = createPersistedState({
  key: currentVersion,
  paths: ['user.current', 'user.token', 'user.refreshToken', 'user.groupToken', 'user.session_started', 'questionnaire.moods', 'questionnaire.colors', 'cart.cart']
})

/** @type {Store} */
const store = new Vuex.Store({
  modules: {
    referral,
    questionnaire,
    ui,
    notification,
    cart,
    user,
    order
  },
  strict: debug || true, // @todo ???
  plugins: [persistState]
})

dropOldVersions()

if (module.hot) {
  module.hot.accept([
    './modules/referral/store',
    './modules/questionnaire/store',
    './modules/ui/store',
    './modules/notification/store',
    './modules/cart/cart',
    './modules/user/store/index',
    './modules/user/store/getters',
    './modules/user/store/mutations',
    './modules/user/store/mutation-types',
    './modules/order/index',
    './modules/order/actions',
    './modules/order/mutations',
    './modules/order/mutation-types'
  ], () => {
    store.hotUpdate({
      modules: {
        referral: require('./modules/referral/store').default,
        questionnaire: require('./modules/questionnaire/store').default,
        ui: require('./modules/ui/store').default,
        notification: require('./modules/notification/store').default,
        cart: require('./modules/cart/cart').default,
        user: require('./modules/user/store').default,
        order: require('./modules/order').default
      },
      plugins: [
        persistState
      ]
    })
  })
}
export default store
